import React from "react"
import { useTranslation } from "react-i18next"
import { StaticImage } from "gatsby-plugin-image"

import Store from "../../../images/ags.png"
import Section from "../../Section/Section"
import LinkButton from "../../LinkButton/LinkButton"

import "./Banner.scss"

export default function Banner() {
  const { t } = useTranslation()

  return (
    <div className="banner">
      <Section>
        <div className="section-content">
          <h1 className="text-left">
            <nobr>{t("Banner.title_1")}</nobr> {t("Banner.title_2")}
          </h1>
          <p className="text-left">{t("Banner.description")}</p>
          <LinkButton to="/home#contact" variant="primary">
            {t("Banner.contact")}
          </LinkButton>
        </div>
        <StaticImage
          style={{ margin: "30px auto" }}
          imgStyle={{ objectFit: "contain" }}
          width={450}
          height={316}
          src="../../../images/image1.png"
          className="banner-image"
          alt="Illustration of a phone using the Dayra app, surrounded by a man and woman expressing appreciation."
          loading="eager"
          placeholder="none"
        ></StaticImage>
        {/* Removed the App and google store logos */}
        <div className="bottom-banner">
          <div className="banner-text">
            <p>{t("Banner.download")}</p>
          </div>
          <img
            width="250"
            height="37"
            src={Store}
            className="app-store-icons"
            alt="App Store and Google Play Store"
          ></img>
        </div>
      </Section>
    </div>
  )
}
