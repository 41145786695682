import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"

import productiveLoan from "../images/productiveLoan.png"
import Layout from "../components/Layout/Layout"
import Banner from "../components/LandingPage/Banner/Banner"

const Offering = loadable(() =>
  import("../components/LandingPage/Offering/Offering")
)
const Integration = loadable(() =>
  import("../components/LandingPage/Integration/Integration")
)
const WhyDayra = loadable(() =>
  import("../components/LandingPage/WhyDayra/WhyDayra")
)
const Partners = loadable(() =>
  import("../components/LandingPage/Partners/Partners")
)
const Blog = loadable(() => import("../components/LandingPage/Blog/Blog"))
const Contact = loadable(() =>
  import("../components/LandingPage/Contact/Contact")
)

export default function Home({ data }) {
  const { featuredBlogPost } = data.allContentfulFeaturedPost.edges[0].node

  return (
    <Layout
      title="Home"
      image={{
        src: productiveLoan,
        width: 400,
        height: 222,
        alt: "Small kiosk shop owner using the Dayra mobile app.",
      }}
    >
      <Banner />
      <Offering />
      <Integration />
      <WhyDayra />
      <Partners />
      {/* <Blog
        title={featuredBlogPost?.title}
        author={featuredBlogPost?.author?.name}
        date={featuredBlogPost?.publishDate}
        summary={featuredBlogPost?.description?.description}
        image={featuredBlogPost?.heroImage?.image}
        imageAlt={featuredBlogPost?.heroImage?.altText}
        slug={featuredBlogPost?.slug}
      /> */}
      <Contact />
    </Layout>
  )
}

export const query = graphql`
  query($locale: String) {
    allContentfulFeaturedPost(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          featuredBlogPost {
            ... on ContentfulBlogPost {
              contentful_id
              __typename
              title
              description {
                description
              }
              publishDate(formatString: "Do MMMM, YYYY")
              slug
              author {
                name
              }
              heroImage {
                ... on ContentfulMainImageComponent {
                  contentful_id
                  __typename
                  altText
                  image {
                    gatsbyImageData(
                      layout: CONSTRAINED
                      placeholder: BLURRED
                      width: 750
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
